
<template>
  <div class="content" style="overflow: auto">
    <div class="view pa20 mr" style="border-bottom: 1px solid #E6EBEB;">
          <span
              style="color: #51CBCD; font-size: 14px;border-bottom: 3px solid #51CBCD;;padding-bottom: 20px;margin-left: 20px"
          >页面设置</span
          >
    </div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
      <el-form-item prop="goodPhoto" style="margin-top: 30px" label="顶部轮播图">
        <div style="display: flex">
          <el-upload
              :action="uploadUrl"
              :class="{
              disabled: uploadDisabled,
            }"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :on-success="goodsUpload"
              :file-list="ruleForm.goodPhoto"
              :before-upload="beforeAvatarUploadImg"
              :limit="10"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog
              :visible.sync="dialogVisible"
              append-to-body
              :modal-append-to-body="false"
          >
            <img width="100%" :src="dialogImageUrl" alt=""/>
          </el-dialog>
        </div>
        <p class="ma" style="color: rgb(150, 150, 150)">
          图片上传比例为1:1，支持格式png、jpg、jpeg，大小不超过1M；
        </p>
      </el-form-item>
      <el-form-item style="margin-top: 30px" label="分类名称">
        <div class="shop_wrap">
          <div class="type_list" v-if="isAdd">
            <div class="type_item"
                 v-for="(item,index) in shopTypeList" :key="index">{{ item.name }}
              <img src="../../assets/images/close_ZDY.png" alt="" v-if="ifShow" @click.stop="deleteType(item,index)"
                   style="position: absolute;right: -9px;top: -9px;height: 18px;width: 18px">
            </div>
          </div>
          <div class="type_list" v-if="isAdd==false">
            <div v-for="(item,index) in shopTypeList" :key="index" style="width: 30%;margin-right: 20px">
              <el-input
                  placeholder="请输入分类名称"
                  v-model="item.name"
                  clearable
                  maxlength="50"
                  show-word-limit
              />
            </div>
          </div>
        </div>
        <div style="margin-top: 20px;display: flex;justify-content: space-between" v-if="ifShow==false&&isAdd">
          <el-button type="primary" class="ma ml10" @click="open"
          >+ 添加分类
          </el-button
          >
          <div style="display: flex">
            <el-button type="primary" class="ma ml10" @click="update"
                       style="border: 1px solid #51CBCD;color: #51CBCD;background: #FFFFFF;display: flex"
            ><img src="../../assets/images/zidingyi.png" alt="" style="width: 14px;height: 14px;align-self: center"> 修改
            </el-button
            >
            <el-button type="primary" class="ma ml10" @click="del"
                       style="border: 1px solid #51CBCD;color: #51CBCD;background: #FFFFFF;display: flex"
            ><img src="../../assets/images/zidingyiDel.png" alt="" style="width: 14px;height: 14px;align-self: center">
              删除
            </el-button
            >
          </div>
        </div>
        <div style="margin-top: 20px;display: flex;justify-content: flex-end" v-if="ifShow">
          <div style="display: flex">
            <el-button type="primary" class="ma ml10" @click="quxiao"
                       style="border: 1px solid #DCDFE6;color: #606266;background: #FFFFFF;display: flex"
            > 取消
            </el-button
            >
            <el-button type="primary" class="ma ml10" @click="quxiao"
                       style="border: 1px solid #51CBCD;color: #FFFFFF;background: #51CDCB;display: flex"
            > 完成
            </el-button
            >
          </div>
        </div>
        <div style="margin-top: 20px;display: flex;justify-content: flex-end" v-if="isAdd==false">
          <div style="display: flex">
            <el-button type="primary" class="ma ml10" @click="quxiao"
                       style="border: 1px solid #DCDFE6;color: #606266;background: #FFFFFF;display: flex"
            > 取消
            </el-button
            >
            <el-button type="primary" class="ma ml10" @click="wancheng"
                       style="border: 1px solid #51CBCD;color: #FFFFFF;background: #51CDCB;display: flex"
            > 完成
            </el-button
            >
          </div>
        </div>
      </el-form-item>
    </el-form>
    <el-dialog
        title="+ 新增分类"
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        :destroy-on-close="true"
        width="50%"
        center
        @close="close_type"
    >
      <el-form
          ref="ruleForm_type" :model="ruleForm_type" :rules="rules_type1" label-width="auto" class="view pa24"
          label-position="left"
      >
        <el-form-item label="分类名称" prop="name">
          <el-input
              placeholder="请输入分类名称"
              v-model="ruleForm_type.name"
              clearable
              maxlength="50"
              show-word-limit
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {addPageImg, queryPgaeSeting, delPgaeImg, delPageTypeById,updatePgaeSeting,addPageType,addPageImgOne} from "../../api/content";

export default {
  data() {
    return {
      centerDialogVisible: false,
      uploadUrl: this.$store.state.uploadingUrl,
      ossUrl: this.$store.state.ossUrl,
      dialogImageUrl: "",
      dialogVisible: false,
      ruleForm: {
        name: "",
        goodPhoto: [], //商品图片
      },
      ruleForm_type: {
        name: ''
      },
      fileList: [],
      rules: {},
      rules_type1: {
        name: [{required: true, message: "请输入分类名称"}],
      },
      shopTypeList: [],
      id: '',
      ifShow: false,
      isAdd:true
    };
  },
  computed: {
    uploadDisabled() {
      return this.ruleForm.goodPhoto.length >= 10;
    },
  },
  mounted() {
    this.id = this.$route.query.dataId
    this.getDetails(this.$route.query.dataId)
  },
  methods: {
    async getDetails(id) {
      const details = await queryPgaeSeting({pageId: id});
      if (details.data.pageImgModelList) {
        this.ruleForm.goodPhoto = details.data.pageImgModelList
      }
      if (details.data.pageTypeModelList) {
        this.shopTypeList = details.data.pageTypeModelList
      }
    },
    close_type() {
      this.ruleForm_type.name = ''
    },
    submitForm(payload) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(this.ruleForm));
          this.$emit("submitForm", data);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
// //商品图片
    async goodsUpload(response, file, fileList) {
      this.$message.closeAll();

      let ajax = {
        pageId: this.id,
        url:`https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/${file.response.data}`
      }
      await addPageImgOne(ajax)

      this.$message({
        message: "上传成功",
        type: "success",
      });
      // let arr = [];
      // fileList = fileList.map((item) => {
      //   if (item.response) {
      //     arr.push({
      //       url: `https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/${item.response.data}`,
      //       uid: item.uid,
      //     });
      //   } else {
      //     arr.push(item);
      //   }
      // });
      //
      // this.ruleForm.goodPhoto = arr;
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    beforeAvatarUploadImg(file) {
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1M!");
      }
      return isLt2M;
    },
    async handleRemove(file, fileList) {
      this.ruleForm.goodPhoto = fileList;
      let ajax = {
        dataId: file.dataId
      }
      await delPgaeImg(ajax)
      this.$message.success('删除成功')
    },
    open() {
      this.centerDialogVisible = true
    },
    deleteType(item, index) {
      this.$confirm('删除该分类后，该分类所属的商品或文章都将删除，不在线上页面展示，确定删除该分类吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let ajax = {
          dataId: item.dataId
        }
        delPageTypeById(ajax);
        this.shopTypeList.splice(index, 1);
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });


    },
    async determine() {
      let ajax = {
        pageId:this.id,
        name:this.ruleForm_type.name
      }
      await addPageType(ajax)
      this.$message.success('新增成功')
      // this.shopTypeList.push({name: this.ruleForm_type.name, ifShow: 1})
      this.centerDialogVisible = false
      this.getDetails(this.id)
    },
    async update() {
      // let arr = this.ruleForm.goodPhoto.map((item) => item.url);
      // let arr1 = this.shopTypeList.map((item) => item.name);
      // let data = {
      //   associationId: localStorage.getItem("associationId"),
      //   pageId: this.id,
      //   typeNames: arr1.join(','),
      //   urls: arr.join(",")
      // };
      // this.$message({
      //   message: "正在保存",
      // });
      // const result = await addPageImg(data);
      // console.log(result)
      // this.$message({
      //   message: "保存成功",
      //   type: "success",
      // });
      // this.$router.push('/customPage')
      this.isAdd = false
    },
    del() {
      this.ifShow = true
    },
    quxiao() {
      this.ifShow = false
      this.isAdd = true
    },
    async wancheng(){
      let ajax = {
        dataId:this.id,
        pageTypeModelList:this.shopTypeList
      }
      await updatePgaeSeting(ajax)
      this.getDetails(this.id)
      this.isAdd = true
      this.$message.success('修改成功')
    }
  },
};
</script>

<style lang="scss" scoped>
.content {
  background: #FFFFFF;
}

/deep/ .disabled .el-upload--picture-card {
  display: none;
}

.shop_wrap {
  border: 1px dashed #DCDFE6;
  border-radius: 4px;
  padding: 20px;
}

.type_list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #E6EBEB;
}

.type_item {
  margin-right: 10px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  position: relative;
  padding: 0 10px;
  background: #51CD91;
  color: #FFFFFF;
}
</style>

